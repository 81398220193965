import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {settingReducer} from "./settingSlice";

const reducer = combineReducers({
    setting: settingReducer,
})
export const store = configureStore({
    reducer
})

export default function configureAppStore() {
    return configureStore({
        reducer
    })
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof configureAppStore>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useSettingSelector: TypedUseSelectorHook<RootState> = useSelector;

import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import "./i18n";
import rocket from "./asset/resource/icons/deployment_159.svg"
import LogRocket from "logrocket";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const LazyApp = lazy(() => {
    LogRocket.init('6jbbck/arrowhackscom');

    return new Promise(resolve => {
        // @ts-ignore
        setTimeout(() => resolve(import('./App')), 300);
    })
})

root.render(
    <React.StrictMode>
        <Suspense fallback={<div className="suspense_logo">
            <img src={rocket} alt="arrowhacks rocket" className="image-height-xl"/>
        </div>}>
            <Provider store={store}>
                <BrowserRouter basename="/">
                    <LazyApp/>
                </BrowserRouter>
            </Provider>
        </Suspense>
    </React.StrictMode>
);
